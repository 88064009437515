document.addEventListener('DOMContentLoaded',function(){
    iniciarApp();
})

function iniciarApp(){
    modulos();
    temas();
    //navegacionFija();
    //scrollNav();
    //botonPlay();
}
/*
function navegacionFija(){
    const barra = document.querySelector('.header'); 
    const sobreFestival= document.querySelector('.sobre-festival');
    const body = document.querySelector('body');
    window.addEventListener('scroll', function(){
       // console.log(sobreFestival);
        if(sobreFestival.getBoundingClientRect().top<0){
            //console.log('Ya pasamos el elemento');
            barra.classList.add('fijo');
            body.classList.add('alto-header');
        } else {
            //console.log('Aún no...');
            barra.classList.remove('fijo');
            body.classList.remove('alto-header');
        }
    })
}

function scrollNav(){
    console.log('Inico de scrollNav');
    const enlaces = document.querySelectorAll('.navegacion-principal a');
    console.log(enlaces);
    enlaces.forEach( enlaces => {
        enlaces.addEventListener('click', function(e){
            e.preventDefault();
            const seccionScroll = e.target.attributes.href.value;
            console.log(seccionScroll);
            const seccion = document.querySelector(seccionScroll);
            seccion.scrollIntoView({behavior:"smooth"});
        })
    });
}
*/
function overlay(i){
    const imagen = document.createElement('picture');
    const div = document.createElement('div');
    const cerrarModal = document.createElement('p');
    const body = document.querySelector('body');
    div.className = 'overlay';
    
    // boton cerra modal
    cerrarModal.onclick = function(){
        const body = document.querySelector('body');
        div.remove();
        body.classList.remove('fijar-body');
    }
    
    div.onclick = function(){
        const body = document.querySelector('body');
        div.remove();
        body.classList.remove('fijar-body');
    }

    imagen.innerHTML = `
    <div class="caja_overlay">
    <img loading="lazy" width="320" height="180" src="../Imagenes/${i}.png" alt="Imagen no disponible" class="img_video">
    <p class="cerrar">[CERRAR]</p>
    </div>
    `;

    // cerrar modal
    cerrarModal.classList.add('boton-cerrar');
    cerrarModal.textContent = 'X';
    div.appendChild(imagen);
    //div.appendChild(cerrarModal);
    body.appendChild(div);
    body.classList.add('fijar-body');
}
function temas(){
    const temas = document.querySelectorAll('.div_curso .tema');
    temas.forEach( boton => {
        boton.addEventListener('click', function(e){
            const id = boton.attributes['data-tema'].value;
            console.log(id);
        });
    });
}

function modulos(){
    const modulos = document.querySelectorAll('.div_curso .modulo');
    modulos.forEach( boton => {
        boton.addEventListener('click', function(e){
            const id = boton.attributes['data-modulo'].value;
            const clase = document.querySelector('#temas_'+id).className;
            console.log(clase);
            if(clase == 'temas ocultar'){
                document.querySelector('#temas_'+id).classList.remove('ocultar');
                boton.classList.add('abrir');
            } else {
                document.querySelector('#temas_'+id).classList.add('ocultar');
                boton.classList.remove('abrir');
            }
            //document.querySelector('#temas_'+id).classList.remove('ocultar');
            //console.log(boton.attributes);
            // mostrar div
        });
    });
}
function botonPlay(){
   //console.log('botonPlay');
    const botonPlay = document.querySelectorAll('.contenedor .boton_audio');
    const sound = new Audio();
    let progresion = 0;
    let id_ = 0;
    botonPlay.forEach( boton => {
        boton.addEventListener('click', function(e){
            //regresarImg();
            const id = e.target.attributes['data-id'].value;
            const mp3 = e.target.attributes['data-mp3'].value;
            const activo = e.target.attributes['data-activo'].value;
            //console.log(id,mp3,activo);
            // vamos acambiar el el boton de pausa por medio de activo
            switch (activo) {
                case '0': // Inicia play del audio
                    regresarImg(id);
                    //console.log('Cambiar Pausa');
                    e.target.attributes['data-activo'].value='1';
                    e.target.src='build/img/icon-pausa.svg';
                    document.querySelector("#audio_play_"+id).classList.add('activo');
                    sound.src = 'Audios/'+mp3+'.mp3';
                    sound.play();
                    //console.log('desde play', progresion);
                    id_ = id;
                    break;
                case '1':
                    //console.log('Cambiar Play');
                    e.target.attributes['data-activo'].value='0';
                    e.target.src='build/img/icon-play.svg';
                    document.querySelector("#audio_play_"+id).classList.remove('activo');
                    sound.pause();
                    e.target.attributes['data-activo'].value='2';
                    break;
                case '2': // se quita la pausa
                    e.target.attributes['data-activo'].value='1';
                    e.target.src='build/img/icon-pausa.svg';
                    document.querySelector("#audio_play_"+id).classList.add('activo');
                    sound.play();
                    break;
            
                default:
                    break;
            }
            if(activo == '0') {
                

            } else {
                
            }
            // Checar ruta del sonido
            //console.log('Ruta del sonido: ',sound.src);
        });
    });  
    // sonido barra de progresion
    sound.addEventListener('timeupdate',function(e){
        //console.log('timeupdate');
        //document.getElementById('progresion_0').style.width = (this.currentTime * 100 / this.duration)+'%';
        //console.log('Audio current',sound.currentTime,this.currentTime,e.currentTime);
        //console.log(document.getElementById('progresion_0'));
        //progresion = this.currentTime * 100 / this.duration;
        document.getElementById('progresion_'+id_).style.width = (this.currentTime * 100 / this.duration)+'%';
        
    });
}

function regresarImg(id){
    const audios = document.querySelectorAll('.contenedor .boton_audio');
    const divActivo = document.querySelectorAll('.contenedor .activo');
    //console.log('divActivo',divActivo);
    audios.forEach( audios => {
            //console.log('cambiar imagen');
           // 
           const id_ = audios.attributes['data-id'].value;
            //console.log('id: ', id,id_);
            
            audios.attributes['data-activo'].value=0;
            //console.log(audios.attributes['data-activo'].value);
            audios.src = 'build/img/icon-play.svg';
            // poner a cero la barra de progresion
            document.getElementById('progresion_'+id_).style.width = '0%';
            
        }
    );
    divActivo.forEach( divActivo_ => {
            //console.log('cambiar imagen');
            divActivo_.classList.remove('activo');
            //console.log('forEach ', divActivo_);
        }
    );
}

function errorMensaje(campo){
    const mensajeError = document.querySelector('#mensajeError');
    mensajeError.textContent = '';
    const p = document.createElement('P');

    // console.log(mensajeError);
    campo.focus();
    campo.classList.add('vacio')
    //console.log('El campo es obligatorio');
    //console.log(campo.placeholder);
    let mensaje = '';
   if (typeof campo.placeholder === 'undefined') {
        mensaje = 'Ingresa: Selecciona una opción';
    } else {
        mensaje = 'Ingresa: '+ campo.placeholder;
    }
    //const mensaje = 'Ingresa: '+ campo.placeholder;
    p.textContent= mensaje;
    //mensajeError.textContent = mensajeError;
    mensajeError.append(p);
    console.log(mensaje);
}
function ValidaEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}
